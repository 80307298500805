import { Component, Host, Prop, h } from '@stencil/core';

@Component({
  tag: `ptz-header-menu-drawer-item`,
  styleUrl: `ptz-header-menu-drawer-item.scss`,
  shadow: false,
})
export class PTZHeaderMenuDrawerItem {
  /** Define o nome a ser exibido */
  @Prop() name: string = '';
  /** Indica se possui o badge new */
  @Prop() isNewItem?: boolean = false;
  /** Indica se possui subcategories */
  @Prop() hasSubCategories?: boolean = false;

  render() {
    const containerClass = { 'ptz-header-menu-drawer-item-container': true, 'ptz-header-menu-drawer-item-subcategories': this.hasSubCategories };
    const labelClass = 'ptz-header-menu-drawer-item-label';

    const badgeNew = () => <ptz-badge size="xs" color="chart-orange-dark">Novo</ptz-badge>;

    const arrowIcon = () => <ptz-icon brand="ptz" variant="line" name="petz-chevron-right" size="md" color="neutral-mid"></ptz-icon>;

    return (
      <Host>
       <div class={containerClass}>
        <span class={labelClass}>
          <span>{this.name}</span>
          {this.isNewItem && badgeNew()}
        </span>
        {this.hasSubCategories && arrowIcon()}
      </div>
      </Host>
    );
  }
}
